import React,{useEffect,useState} from 'react';
import { useQuill } from 'react-quilljs';

import SelectInputForm from '../../components/forms/select-input-form';
import InputForm from '../../components/forms/input-form';
import ButtonForm from '../../components/forms/button-form';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import ValidatorUtil from '../../utils/validator-util';
import { errorMessages } from '../../utils/error-messages-util';
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

export const LessonReferenceEditor = (props) => {
  const { quill, quillRef } = useQuill();

  const [subCategory, setSubCategory] = useState(0);
  const [category, setCategory] = useState(0);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [lessonSubSubjects, setLessonSubSubjects] = useState([]);
  const [subCategoryError, setSubCategoryError] = useState('');
  const [html,setHtml] = useState('');
  const [text,setText] = useState('');
  const [titleError, setTitleError] = useState('');

  const {item} = props;

  
  useEffect(()=>{
    if (quill) {
      if(item && item.html){
        quill.clipboard.dangerouslyPasteHTML(item.html)
      }
     
        quill.on('text-change', (delta, oldDelta, source) => {
          console.log('Text change!');
          console.log(quill.getText()); // Get text only
          setText(quill.getText());
          setHtml(quillRef.current.firstChild.innerHTML)
          console.log(quill.getContents()); // Get delta contents
          console.log(quill.root.innerHTML); // Get innerHTML using quill
          console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
        });
      }
  },[quill])

  useEffect(()=>{
    
    if(item){
       setTitle(item.title);
    setSubtitle(item.subtitle);
    var lessonSubjects = props.lessonSubjects.find(ls => ls.value  === item.lessonCategory.id);
    setLessonSubSubjects(lessonSubjects.subCategories);
    setCategory({label:item.lessonCategory.name,value:item.lessonCategory.id});
    setSubCategory({label:item.lessonSubCategory.name,value:item.lessonSubCategory.id})
    }
   
  },[item])

  const onCategoryChanged = (e) =>{
    var lessonSubjects = props.lessonSubjects.find(ls => ls.value  === e.value);
    setLessonSubSubjects(lessonSubjects.subCategories);
    setCategory(e);
    setSubCategory(lessonSubjects.subCategories[0])
}
const isFormValid = () =>{
  let result = true;
  if(ValidatorUtil.isEmptyField(title)){
    setTitleError(errorMessages.fieldRequired);
    result = false;
  }else{
    setTitleError('');
  }
  if (subCategory === null || subCategory === 0) {
    setSubCategoryError(errorMessages.chooseSelection)
    result = false;
  } else {
    setSubCategoryError('')

  }
  return result
}
const onSubmit = () =>{
  if(isFormValid()){
  const obj = {
    title,
    subtitle,
    lessonCategoryId:category.value,
    lessonSubCategoryId:subCategory.value,
    html,
    text
  }
  if(item){
    obj.id = item.id;
  }
  props.onSubmit(obj);
}
}

  return (
    <div style={{marginTop:'50px',display:'flex' , flexDirection:'column',alignItems:'center'}}>
        <table style={{minWidth:'60rem'}}>
          <tbody>
        <tr style={{height:'8rem',verticalAlign:'baseline'}}>
            <td colSpan={2} style={{textAlign:'center'}}>
            <ButtonForm text={'שלח'} style={{width:'100%'}} onSubmit={onSubmit}/>
            </td>
          </tr>
          <tr>
          <td style={{padding:'0 5px'}}>
            <InputForm title={'הזן כותרת משנה'} style={{minHeight:'10rem'}} value={subtitle} onChange={setSubtitle}/>
            </td>
            <td style={{padding:'0 5px'}}>
            <InputForm title={'הזן כותרת'}  style={{minHeight:'10rem'}} errorMessage={titleError} value={title} onChange={setTitle}/>
            </td>
          </tr>
          <tr>
          <td style={{padding:'0 5px'}}>
            {lessonSubSubjects && lessonSubSubjects.length > 0 && <SelectInputForm
      title={'בחר תת קטגוריה'}
      value={subCategory}
      onChange={setSubCategory}
      options={lessonSubSubjects}
      errorMessage={subCategoryError}
    />}
            </td>
            <td style={{padding:'0 5px'}}>
            {props.lessonSubjects && props.lessonSubjects.length > 0 && <SelectInputForm
      title={'בחר קטגוריה'}
      value={category}
      onChange={onCategoryChanged}
      options={props.lessonSubjects}
      errorMessage={subCategoryError}
    />}
            </td>
           
          </tr>
          </tbody>
        </table>
       
        
       
 
        <label style={{color: '#68676a',letterSpacing:'-0.2px'}}>הזן תוכן</label>
    <div style={{ width: '90vw', height:'40rem' }}>
        
      <div ref={quillRef} />
    </div>
   
  


  
    
    </div>
  );
};
